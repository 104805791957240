<template>
  <div>
    <v-card flat>
      <v-container fluid>
        <v-row class="child-flex">
          <div>
            <v-toolbar dense elevation="0">
              <base-drop-down-menu-button
                ref="refDropDownMenuActions"
                :icon-name="iconSend"
              >
                {{ commandDownload }}
                <template v-slot:commands>
                  <base-menu-item @click="download(downloadReportType.asPdf)">
                    <template v-slot:iconName>
                      {{ iconPdf }}
                    </template>
                    <template v-slot:tooltipContent>
                      <div>
                        {{ tooltipPdf }}
                      </div>
                    </template>
                    {{ commandPdf }}
                  </base-menu-item>

                  <base-menu-item @click="download(downloadReportType.asWord)">
                    <template v-slot:iconName>
                      {{ iconWord }}
                    </template>
                    <template v-slot:tooltipContent>
                      <div>
                        {{ tooltipWord }}
                      </div>
                    </template>
                    {{ commandWord }}
                  </base-menu-item>

                  <base-menu-item @click="download(downloadReportType.asExcel)">
                    <template v-slot:iconName>
                      {{ iconExcel }}
                    </template>
                    <template v-slot:tooltipContent>
                      <div>
                        {{ tooltipExcel }}
                      </div>
                    </template>
                    {{ commandExcel }}
                  </base-menu-item>
                </template>
              </base-drop-down-menu-button>
              <base-button @click="download(downloadReportType.asPrintPdf)">{{
                commandPrint
              }}</base-button>
            </v-toolbar>
          </div>

          <div>
            <v-toolbar dense elevation="0">
              <v-spacer></v-spacer>
              <slot name="rightToolbar"> </slot>
            </v-toolbar>
          </div>
        </v-row>
      </v-container>
    </v-card>
    <v-divider></v-divider>
    <br />
    <div class="text-center ">
      <p class="font-weight-medium headline">{{ displayHeader }}</p>
      <p class="font-weight-regular title">{{ currentDate }}</p>
      <br />
    </div>
  </div>
</template>

<script>
import {
  iconExcel,
  iconPdf,
  iconReport,
  iconSend,
  iconTrash,
  iconWord
} from "@/design/icon/iconConst";
import { moduleNames } from "@/model/solution/moduleModel";
import { downloadReportType } from "@/model/report/reportModel";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "ReportingCard",
  components: {
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    BaseButton: () => import("@/components/shared/base/BaseButton")
  },
  props: {
    reportModule: {
      type: String,
      default: "Folder"
    }
  },
  data() {
    return {
      iconReport: iconReport,
      iconSend: iconSend,
      iconPdf: iconPdf,
      iconExcel: iconExcel,
      iconDelete: iconTrash,
      iconWord: iconWord,
      downloadReportType: downloadReportType
    };
  },
  computed: {
    /**
     * Report Display Heading
     * @return {string}
     */
    displayHeader() {
      return `Questys ${this.getReportModuleTitle} Detail Report`;
    },

    /**
     * command Download
     * @return {string}
     */
    commandDownload() {
      return "Download";
    },

    /**
     * command Print
     * @return {string}
     */
    commandPrint() {
      return "Print";
    },

    /**
     * command Pdf
     * @return {string}
     */
    commandPdf() {
      return "Pdf";
    },

    /**
     * command Word
     * @return {string}
     */
    commandWord() {
      return "Word";
    },

    /**
     * command Excel
     * @return {string}
     */
    commandExcel() {
      return "Excel";
    },

    /**
     * Tooltip Pdf
     * @return {string}
     */
    tooltipPdf() {
      return "Download as Pdf Document";
    },

    /**
     * Tooltip Word
     * @return {string}
     */
    tooltipWord() {
      return "Download as Word Document";
    },

    /**
     * Tooltip Excel
     * @return {string}
     */
    tooltipExcel() {
      return "Download as Excel Document";
    },

    /**
     * Get Report Module Title
     * @return {String|string}
     */
    getReportModuleTitle() {
      if (this.reportModule === moduleNames.Folder) return "Records";
      return this.reportModule;
    },

    /**
     * Get Current Date
     * @return {Date}
     */
    currentDate() {
      return new Date();
    }
  },

  methods: {
    /**
     * Download Report
     * @param {asPrintPdf: number, asExcel: number, asPdf: number, asWord: number} downloadType
     */
    download(downloadType) {
      this.$emit(eventNames.downloadReport, downloadType);
    }
  }
};
</script>

<style scoped></style>
